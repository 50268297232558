.references {
  align-items: center;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  align-items: left;
}

.references h2 {
  font-weight: 900;
  text-align: center;
}

.references-paragraph {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.references-paragraph {
  align-items: left;
}
