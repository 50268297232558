.slider {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  min-width: 500px;
  font-size: 1.2rem;
}

.slider-indicator-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.slider-image-wrapper {
  border-radius: 10px;
  flex: 1;
  margin-bottom: 10px;
  background-color: rgb(209, 209, 209);
  overflow: hidden;
}

.slider-image-link {
  position: relative;
}

.slider-image-link:hover {
  cursor: pointer;
}

.slider-image-hover-text {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100px;
  height: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border: 1px solid black;
  box-shadow: 0px 0px 100px 2px black;
  border-radius: 5px;
  padding: 10px;
  opacity: 0.9;
}

.slider-image-hover-text p {
  margin: 0;
  color: black;
  font-weight: bold;
}

.slider-image-wrapper img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  transition: 500ms;
}

.slider-image-wrapper:hover img {
  transform: scale(1.05);
}

.slider-image-wrapper:hover .slider-image-hover-text {
  display: flex;
  z-index: 1;
}

.slider-indicator {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  border: 1px solid black;
  bottom: 0px;
  left: 40%;
}

.slider-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50px;
}

.slider-button:hover {
  cursor: pointer;
}

#slider-next:hover,
#slider-prev:hover {
  background-color: var(--secondary-color);
  border: 1px solid rgb(255, 255, 255);
}

#slider-next:hover svg,
#slider-prev:hover svg {
  color: white;
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 700px) {
  .slider {
    width: 100%;
    min-width: 90%;
  }
  .slider {
    font-size: 1rem;
  }
}
