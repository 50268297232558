.home-services {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-services h1 {
  text-align: center;
}

.home-services-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 30px;
}

.home-services-title {
  margin-bottom: 50px;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .home-services-title {
    margin-bottom: 20px;
    text-align: center;
  }
}
