.specialization {
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}

.specialization .specialization-image-container img {
  width: 100%;
  max-width: 600px;
}

/* PARAGRAPHS */
.specizalization-paragraph {
  max-width: 600px;
}
