.service-title {
  text-align: center;
  padding-bottom: calc(var(--seperator-height) - 50px);
  font-size: 1.5rem;
}

.service-title h1::first-letter {
  text-transform: capitalize;
}

.service-cards {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--primary-color);
  fill: var(--primary-color);
}

.service-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1280px;
}

.service-cards-container > .card-wrapper {
  flex-basis: 25%;
  margin: 0px 15px 30px 15px;
}

@media screen and (max-width: 750px) {
  .service-cards {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .service-cards-container {
    flex-direction: column;
  }
  .service-cards-container > .card-wrapper {
    flex-basis: auto;
    margin: 0px 15px 30px 15px;
  }
}
