.introduction {
}

/* IMG */
.introduction .image-container {
  align-self: center;
  flex: 1;
}
.introduction .image-container img {
  width: 100%;
}

/* PARAGRAPHS */
.introduction-paragraph {
  flex: 1;
}

.introduction-paragraph h1 {
  font-size: 4rem;
}

.introduction-paragraph h1 span {
  color: var(--ss-pink);
}

.introduction-paragraph button {
  margin-top: 20px;
  display: block;
}

/* MEDIA */
@media only screen and (max-width: 1100px) {
  .introduction .image-container {
    padding-top: 25px;
  }

  .introduction-paragraph h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 700px) {
  .introduction-paragraph h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .introduction-paragraph h1 {
    font-size: 1.5rem;
  }
}
