.explanation {
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}

.explanation .explanation-image-container img {
  width: 100%;
  max-width: 600px;
}

/* PARAGRAPHS */
.explanation-paragraph {
  max-width: 600px;
}
