.cost-calculator {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 49.9%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  background-color: var(--primary-color);
  z-index: 3;
  box-shadow: 0px 0px 100px 20px var(--secondary-color);
  display: none;
  overflow-y: scroll;
}

.cost-calculator #calculator-closer {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 20px 0;
  background-color: #426ef0;
  color: white;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 3;
}
.cost-calculator #calculator-closer:hover {
  background-color: var(--secondary-color);
}

.cost-calculator-open {
  display: flex;
}

.cost-calculator-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.calculator-header {
  position: relative;
  margin-right: 15px;
  margin-left: 15px;
  text-align: center;
}
.calculator-header h1 {
  font-size: 1.7rem;
}
.calculator-header h2 {
  font-size: 1.3rem;
  margin-right: 20px;
}
.option-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.calculator-options-container {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.options {
  width: 500px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: var(--ss-optional);
}

.calculator-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-container {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  background-color: var(--action-color);
  color: white;
  padding: 15px 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
}

.option-container:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
.option-container:active {
  transform: translateY(1px);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.cost-calculator input {
  margin: 0px 2px 0px 2px;
}

.cost-calculator input[type="radio"]:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  position: relative;
  background-color: var(--ss-pink);
  content: "";
  display: inline-block;
  border: 2px solid white;
}

.cost-calculator input[type="checkbox"]:checked:after {
  width: 10px;
  height: 10px;
  position: relative;
  background-color: var(--ss-pink);
  content: "";
  display: inline-block;
  border: 2px solid white;
}
.option-container p {
  margin: 0;
}

.estimation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  padding: 30px 0px 50px 0px;
  background-color: #f9f9f9;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  gap: 20px;
}

.estimation-container h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.estimation-price {
  font-size: 1.4rem;
  color: var(--action-color);
  font-weight: bold;
  font-style: oblique;
  text-transform: uppercase;
}

.estimation-price br {
  display: none;
}

.estimation-container button {
  padding: 10px 20px;
  background-color: var(--action-color);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 191px;
}

.estimation-container button:hover {
  background-color: var(--secondary-color);
}
@media screen and (max-width: 700px), (max-height: 700px) {
  .cost-calculator {
    width: 100%;
  }
  .estimation-container {
    margin: 0;
  }
  .calculator-header h1 {
    font-size: 1.4rem;
  }
  .calculator-header h2 {
    font-size: 1rem;
  }
  .calculator-options-container {
    margin-top: 10px;
  }
  .options {
    width: 90%;
    padding: 10px;
  }
}
