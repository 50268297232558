.map {
  position: relative;
  margin-right: 20px;
  width: 400px;
  border: 1px solid black;
  border-radius: 20px;
}

.map div {
  border-radius: 20px;
}

.map-ss-logo {
  z-index: 1;
  font-size: 1.5rem;
  color: white;
  position: absolute;
  left: 10px;
  top: 10px;
  font-weight: 900;
}

.map-ss-logo:hover {
  transform: scale(1.05);
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .map {
    width: 100%;
    height: 400px;
    min-height: 400px;
    margin-bottom: 20px;
  }
}
