.contact-bar {
  display: flex;
  width: 100%;
  height: 100px;
  border: 1px solid black;
  border-radius: 50px;
}

.contact-bar > .contact-bar-info {
  flex: 1;
}

.contact-bar-info {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 25px;
}

.contact-bar-info svg {
  margin-right: 20px;
  height: 40px;
  width: 40px;
  color: var(--action-color);
}

.contact-bar-info:not(:first-child)::before {
  content: "";
  height: 100%;
  border: 1px solid black;
  opacity: 0.5;
  margin-right: 30px;
}

@media only screen and (max-width: 900px) {
  .contact-bar {
    display: none;
  }
}
