.section {
  display: flex;
  position: relative;
  justify-content: center;
  min-height: 500px;
  /* Seperator height */
  padding-bottom: var(--seperator-height);
}

.section-default-p p {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.section-content {
  width: 1400px;
  padding-left: calc(2px + 5vw);
  padding-right: calc(2px + 5vw);
  padding-top: calc(8px + 5vw);
}

.section1 {
  background: linear-gradient(#ffffff, var(--primary-color));
}
.section2,
.section4 {
  background-color: var(--third-color);
  fill: var(--third-color);
}
.section3,
.section5 {
  background-color: var(--primary-color);
  fill: var(--primary-color);
  padding-bottom: 75px;
}

@media only screen and (max-width: 1100px) {
  /* top padding to sections which has no seperator*/
  .home .section:not(:nth-child(4)) .section-content {
    padding-top: 0px;
  }
}
@media screen and (max-width: 700px) {
  .section-default-p p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}
