.contact-form {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid black;
  padding: 20px 20px 20px 20px;
  border-radius: 20px;
}

@media only screen and (max-width: 1100px) {
  .contact-form {
    border: none;
  }
}

@media only screen and (max-width: 700px) {
  .contact-form {
    width: 100%;
    padding: 20px 5px 10px 5px;
  }
}

.contact-form .custom-button {
  align-self: flex-start;
}
