.footer {
  min-height: 400px;
  background-color: black;
  background-image: url("../../assets/images/footerBg.png");
  background-size: cover;
  color: rgb(158, 158, 158);
}

.footer-container {
  padding: 100px 10vw 0px 10vw;
  justify-content: space-around;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer ul li {
  margin: 0px 10px 10px 0px;
  padding: 0;
}
.footer-socials {
  display: flex;
  flex-direction: row;
}

.footer-socials li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 20px;
  background-color: var(--action-color);
  border: 1px solid #4f4f4f;
}

.footer-socials li:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
}

.footer-socials li svg {
  color: white;
}

.footer p {
  margin: 0;
}

.footer-links li::first-letter {
  text-transform: capitalize;
}

.footer-links li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.footer-section {
  flex-direction: column;
  max-width: 350px;
  gap: 20px;
}

.footer-logo {
  font-size: 2.5rem;
}

.footer-copyright {
  margin-top: 60px;
  text-align: center;
  padding: 40px;
  border-top: 1px solid #4f4f4f;
}

.footer-copyright span {
  color: white;
}

.footer-tm {
  color: white;
  display: flex;
  align-items: flex-end;
  height: 40px;
  font-weight: bold;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1100px) {
  .footer-tm {
    margin-bottom: 0px;
  }
  .footer-copyright {
    margin-top: 20px;
  }
  .footer-section {
    margin-bottom: 50px;
  }
}
