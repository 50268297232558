.service-card {
  min-width: 325px;
  max-width: 325px;
  min-height: 480px;
  background-color: #f4f8fc;
  flex-direction: column;
  /* Light gray border */
  box-shadow: 0px 0px 2px 0px var(--secondary-color);
  border-radius: 5px;
}

.sc-opt-bg {
  background-color: var(--third-color);
}

.service-card-title {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: var(--secondary-color);
  color: white;
  font-weight: 900;
  height: 75px;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.service-card-content {
  text-align: left;
  padding: 30px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-card-content-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.service-card-content-section p {
  font-size: 1rem;
  margin: 0;
}

.svg-container {
  display: flex;
  border-radius: 25px;
  padding: 2px;
  min-width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  background-color: var(--action-color);
}

@media screen and (max-width: 750px) {
  .service-card {
    max-width: 100%;
    min-width: 100%;
    min-height: 350px;
  }
}

@media screen and (max-width: 400px) {
  .svg-container {
    min-width: 25px;
    height: 25px;
  }
}
