.custom-input {
  display: flex;
  position: relative;
  width: 100%;
  text-transform: uppercase;
}

.custom-input input,
textarea,
select {
  width: 100%;
  font-size: 1rem;
  padding: 15px;
  border-radius: 5px;
  border: 1.5px solid rgba(128, 128, 128, 0.4);
  margin: 20px 5px 20px 5px;
}

[fieldname]:before {
  position: absolute;
  content: attr(fieldname);
  height: 20px;
  font-weight: 900;
  top: -5px;
  left: 5px;
}

@media only screen and (max-width: 1100px) {
  .custom-input input {
    padding: 10px;
  }
  .custom-input textarea {
    padding: 10px;
  }
}
