.navbar {
  width: 100%;
  display: flex;
  height: 125px; /* .nav-padding in app.css */
  z-index: 2;
}

.navbar-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 100px 0px 100px;
}

.navbar-items img {
  cursor: pointer;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  cursor: pointer;
  display: inline-block;
  margin-right: 25px;
  font-size: 1.2rem;
}
.navbar-selected {
  color: var(--action-color);
}

.contact-lang-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  align-items: center;
  color: black;
}
/* Nav mobile button START */
.nav-expand {
  cursor: pointer;
  display: none;
  position: relative;
  min-width: 35px;
  min-height: 25px;
  flex-direction: column;
  justify-content: space-between;
}

.nav-expand-line {
  background-color: black;
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
}

.nav-expand-expanded .nav-expand-line {
  position: absolute;
  top: 45%;
}
.nav-expand-expanded #line1 {
  transition: 800ms ease-in-out;
  transform: rotate(45deg);
}
.nav-expand-expanded #line2 {
  display: none;
}
.nav-expand-expanded #line3 {
  transition: 800ms ease-in-out;
  transform: rotate(-45deg);
}
/* Nav mobile button END */

/* Mobile nav view START */

@media only screen and (max-width: 1100px) {
  .navbar-items {
    padding: 0px 25px 0px 25px;
  }
  .navbar-logo {
    width: 250px;
  }
}

@media only screen and (max-width: 800px) {
  .navbar {
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 2px 0px rgb(0, 0, 0, 0.5);
  }
  .navbar-links,
  .navbar button {
    display: none;
  }
  .nav-expand {
    display: flex;
  }
  .navbar-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 125px;
    left: -825px;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: var(--secondary-color);
    transition: 800ms;
    border-top: 1px solid var(--ss-pink);
    color: rgb(228, 228, 228, 1);
  }
  .navbar-links li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    font-size: 1.3rem;
    margin-right: 0px;
    border-bottom: solid 1px rgb(228, 228, 228, 0.3);
  }

  .navbar-selected {
    color: var(--action-color);
  }
}

@media only screen and (max-width: 400px) {
  .navbar-items {
    padding: 0px 10px 0px 10px;
  }
  .navbar-logo {
    width: 175px;
  }
}
