.work-path {
  display: flex;
}

.work-path-content {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
.work-path-content span {
  font-weight: bold;
}

.work-path-content li {
  display: flex;
}

.work-path-content-image-container {
  margin-top: 10px;
}

.work-path-content li img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
