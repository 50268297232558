.lang-switcher {
  display: flex;
  gap: 10px;
  font-size: 1.2rem;
}
.lang-switcher p {
  margin: 0;
  text-transform: capitalize;
}

.lang-switcher p:hover {
  cursor: pointer;
  color: rgb(56, 56, 56);
}

.lang-selected {
  color: var(--action-color);
  font-size: 1.3rem;
  border-bottom: solid 1px;
}
