.custom-button {
  border: none;
  color: white;
  background-color: var(--action-color);
  min-height: 44px;
  border-radius: 20px;
  font-weight: 600;
  padding: 0.8rem;
  text-transform: uppercase;
}

.custom-button:hover {
  cursor: pointer;
  background-color: var(--secondary-color);
}

.custom-button-loading {
  margin: auto;
  display: block;
  width: 7px;
  height: 7px;
  border: 4px solid var(--action-color);
  border-radius: 50%;
  border-top-color: white;
  border-right-color: white;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
