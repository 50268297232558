body {
  margin: 0;
  overflow-x: hidden;
}

body,
input,
textarea,
select,
button {
  font-family: "Roboto";
}

h2 {
  font-size: 2rem;
}

:root {
  font-size: 16px;
  /* THEME */
  --primary-color: #e7f3ff;
  --secondary-color: #1b2f44;
  --action-color: #426ef0;
  --third-color: #ffffff;
  --ss-pink: #f042df;
  --ss-optional: #f5f5f5;
  /* SEPERATOR */
  --seperator-height: 250px;
}

.flex {
  display: flex;
}

.nav-padding {
  padding-top: 75px; /* Nav height */
}

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 15px;
}
/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-color);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--action-color);
}

/* MEDIA WIDTH 1100 - 700 - 400 */
@media only screen and (max-width: 1100px) {
  .media-1100-flex-cr {
    flex-direction: column-reverse;
  }
  .media-1100-flex-c {
    flex-direction: column;
  }
}

/* MOBILE NAV */
@media only screen and (max-width: 800px) {
  .nav-padding {
    padding-top: 125px;
  }
}

@media only screen and (max-width: 700px) {
  h2 {
    font-size: 1.3rem;
  }
  :root {
    --seperator-height: 150px;
  }
  .media-700-flex-cr {
    flex-direction: column-reverse;
  }
  .media-700-flex-c {
    flex-direction: column;
  }
}
