.contact {
  text-align: center;
}

.contact-title {
  padding-bottom: calc(var(--seperator-height) - 50px);
  font-size: 1.5rem;
}

.contact-title h1::first-letter {
  text-transform: capitalize;
}

.contact-page-container {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: var(--primary-color);
  fill: var(--primary-color);
  padding: 30px 15px 30px 15px;
  padding-bottom: 100px;
}

.contact-p {
  font-size: 1.4rem;
  font-weight: 900;
  margin-bottom: 50px;
  max-width: 700px;
  margin: 0px auto 50px auto;
}

.contact-page-inner-container {
  width: 1200px;
}

.contact-page-inner-container > div {
  margin-bottom: 50px;
}

.contact-container {
  gap: 10px;
}

@media only screen and (max-width: 1100px) {
  .contact-wrapper {
    padding-bottom: 0px;
  }
  .contact-p {
    font-size: 1.2rem;
  }
}
